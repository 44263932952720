import React, { Suspense, useEffect } from "react";
import {
  Switch,
  BrowserRouter,
  Route,
  Redirect,
  useParams,
} from "react-router-dom";
import { useDispatch } from "react-redux";
import { SET_ADMIN } from "./store/admin/types";
import Admin from "./pages/Admin";
import AgencyList from "./pages/AgencyList";
import AgencyForm from "./pages/AgencyForm";
import TotalIncome from "./pages/TotalIncome";
import AgencyHistory from "./pages/AgencyHistory";
import AllAgency from "./component/table/AllAgency";
import HostList from "./pages/HostList";
import HostHistory from "./pages/HostHistory";
import AllHostHistory from "./pages/AllHostLive";


function InviteLinkHandler() {
  const { id } = useParams();
  localStorage.setItem("bdCode", id);
  console.log("id", id);
  return <AgencyForm />;
}

function App() {
  const dispatch = useDispatch();
  const token = localStorage.getItem("TOKEN");
  const key = localStorage.getItem("KEY");

  const params = new URLSearchParams(window.location.search);
  const bdId = params.get("id") || localStorage.getItem("bdId");

  useEffect(() => {
    localStorage.setItem("bdId", bdId);
    if (token && key) {
      dispatch({ type: SET_ADMIN, payload: token });
    }
  }, [token, key, bdId, dispatch]);

  return (
    <div className="App">
      <Suspense fallback={""}>
        <BrowserRouter>
          <Switch>
            <Route path={"/bd/homePage"} component={Admin} />
            <Route path={"/bd/allAgency"} component={AllAgency} />
            <Route path={"/bd/agencyList"} component={AgencyList} />
            <Route path={"/bd/agencyHistory"} component={AgencyHistory} />
            <Route path={"/bd/income"} component={TotalIncome} />
            <Route path={"/inviteLink/:id"} component={InviteLinkHandler} />
            <Route path={"/bd/hostlist"} component={HostList} />
            <Route path={"/bd/hostHistory"} component={HostHistory} />
            <Route path={"/bd/hostLiveHistory"} component={AllHostHistory} />
            <Redirect from="/" to="/bd/homePage" />
          </Switch>
        </BrowserRouter>
      </Suspense>
    </div>
  );
}

export default App;
