import {
  GET_HOST_HISTORY,
  GET_HOST_LIST,
  GET_HOST_LIVE_HISTORY,
} from "./types";

const initialstate = {
  hostList: [],
  hostHistory: [],
  hostLiveHistory: [],
};

export const hostListReducer = (state = initialstate, action) => {
  switch (action.type) {
    case GET_HOST_LIST: {
      return { ...state, hostList: action.payload.data };
    }
    case GET_HOST_HISTORY: {
      return {
        ...state,
        hostHistory: action.payload.data,
      };
    }
    case GET_HOST_LIVE_HISTORY: {
      return {
        ...state,
        hostLiveHistory: action.payload,
      };
    }
    default:
      return state;
  }
};
